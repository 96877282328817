import React, { useEffect, useState } from "react"

import {
  ContainerNews,
  DataItem,
  ImageWrapper,
  MoreRow,
  NewsItems,
  NewsPage,
  NewsText,
  SeeMoreNews,
  SeeMoreSingleNews,
  TextPart,
  Title,
} from "./newsStyle"

import moment from "moment"

const UsefulNews = ({ news, landing, lang, buttonDisplay, setButtonDisplay, locale, apiUrl, pageContext }) => {

  let [items, setItems] = useState(news.length <= 5 ? news.length : 5)

  const addNews = () => {
    setItems(items + 5)
    items + news.length % items === news.length ? setButtonDisplay(false) : setButtonDisplay(true)
  }
  useEffect(()=>{
    news.length <= 5 && setButtonDisplay(false)
  },[])

  return (
    <ContainerNews>
      <NewsPage landing={landing}>
        {news.map((item, index) => (
          (index <= items) && (
            <NewsItems
              landing={landing}
              order={index}
              to={`/${lang}/news/${item.id}`}
              state={{ modal: true }}
              key={index}
              margin={item.margin}
            >
              <ImageWrapper>
                <img style={{ width: "100%" }} src={apiUrl + item.image} alt={item[`title_${locale}`]} />
              </ImageWrapper>
              <TextPart>
                <Title>{item[`title_${locale}`]}</Title>
                <NewsText>{item[`description_${locale}`]}</NewsText>
                <MoreRow>
                  <DataItem>{moment(item.created_at.substring(0, 10)).format("DD.MM.YYYY")}</DataItem>
                  <SeeMoreSingleNews
                    className="see_more_btn"
                  > {pageContext.localeResources.translation.news.more}
                  </SeeMoreSingleNews>
                </MoreRow>
              </TextPart>
            </NewsItems>
          )
        ))}
      </NewsPage>
      <SeeMoreNews
        buttonDisplay={buttonDisplay}
        onClick={addNews}
        className="see_more_btn"
      > {pageContext.localeResources.translation.news.see_more}
      </SeeMoreNews>
    </ContainerNews>
  )
}

export default UsefulNews